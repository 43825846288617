import { TableCell, TableHead, TableRow } from '@mui/material';
import { FC, memo } from 'react';
import { ICustomTableHeaderProps } from './types';
import { uuid } from '@/utils';

const CustomTableHeader: FC<ICustomTableHeaderProps> = ({
	tableId,
	columns,
	searchEnabled,
}) => (
	<TableHead>
		<TableRow>
			{columns.map((col: any, i: number) => (
				<TableCell
					sx={{
						width: col.width,
						// eslint-disable-next-line
						borderRadius: `${i === 0 && !searchEnabled ? '10px' : '0px'} ${i === columns.length - 1 ? '10px' : '0px'
						} 0px 0px`,
						fontWeight: 'bold',
						fontSize: '15px',
						padding: '16px 8px'
					}}
					align={col.align}
					key={`table-header-cell-${tableId}-${uuid()}`}
				>
					{`${col.label}`.toUpperCase()}
				</TableCell>
			))}
		</TableRow>
	</TableHead>
);

export const MemoizedTableHeader = memo(CustomTableHeader);
