import { useCallback, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { AxiosError } from 'axios';
import { SocketContext } from './context';
import { IChatQuery, SocketContextType } from './types';
import { SOCKET_URL } from './constants';
import { IAttachments } from '@/hooks/fetch-hooks/use-file-uploader/types';
import { post } from '@/utils';

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [socket, setSocket] = useState<Socket>();
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

	const connectSocket = useCallback(() => {
		if (!socket) {
			// If the socket is not initialized, initialize it
			const initiate = io(SOCKET_URL, { transports: ['websocket'] });
			setSocket(initiate);
		} else {
			// If the socket is already initialized, just connect
			socket.connect();
		}
	}, [socket]);

	const disconnectSocket = useCallback(() => {
		// setIsAuthenticated(false);
		socket?.disconnect();
	}, [socket]);

	const authenticateSocket = useCallback(
		async (): Promise<void> => {
			try {
				if (socket && socket.id) {
					const response = await post('api/chat/auth', { socket: socket.id });
					if (response.status !== 200) {
						throw new Error(response.data?.error || 'Authentication failed.');
					}

					setIsAuthenticated(true);
				}
			} catch (error) {
				const err = error as AxiosError;
				const data = err.response?.data as { error: string };
				setIsAuthenticated(false);
				console.error('Authentication error:', data?.error);
			}
		},
		[socket]
	);

	const sendMessageToRoom = useCallback(
		async (room: string, message: string): Promise<number> => {
			try {
				if (!socket || !socket.id) {
					throw new Error('Socket is not initialized.');
				}
				const response = await post('api/chat/messages', { data: { room, message } });
				if (response.status !== 201) {
					throw new Error(response.data?.error || 'Failed to send message.');
				}
				return 201;
			} catch (error) {
				const err = error as AxiosError;
				const data = err.response?.data as { error: string };
				console.error('Send message error:', data.error);
				return err.status || 400;
			}
		},
		[socket]
	);

	const sendAttachmentsToRoom = useCallback(
		async (room: string, attachments: IAttachments[]): Promise<number> => {
			try {
				if (!socket || !socket.id) {
					throw new Error('Socket is not initialized.');
				}
				const response = await post('api/chat/messages', { data: { room, attachments } });
				if (response.status !== 201) {
					throw new Error(response.data?.error || 'Failed to send attachments.');
				}
				return 201;
			} catch (error) {
				const err = error as AxiosError;
				const data = err.response?.data as { error: string };
				console.error('Send attachments error:', data.error);
				return err.status || 400;
			}
		},
		[socket]
	);

	const fetchRooms = useCallback(() => {
		if (isAuthenticated) socket?.emit('fetch-rooms');
	}, [socket, isAuthenticated]);

	const joinRoom = useCallback(
		async (room: string): Promise<void> => {
			try {
				if (!socket || !socket.id) {
					throw new Error('Socket is not initialized.');
				}
				const response = await post(`api/chat/rooms/${room}/join`);
				if (response.status !== 200) {
					throw new Error(response.data?.error || 'Failed to join room.');
				}
			} catch (error) {
				const err = error as AxiosError;
				const data = err.response?.data as { error: string };
				console.error('Join room error:', data.error);
			}
		},
		[socket]
	);

	const leaveRoom = useCallback(
		async (room: string): Promise<void> => {
			try {
				if (!socket || !socket.id) {
					throw new Error('Socket is not initialized.');
				}
				const response = await post(`api/chat/rooms/${room}/leave`);
				if (response.status !== 200) {
					throw new Error(response.data?.error || 'Failed to leave room.');
				}
			} catch (error) {
				const err = error as AxiosError;
				const data = err.response?.data as { error: string };
				console.error('Leave room error:', data.error);
			}
		},
		[socket]
	);

	const leaveAllRooms = useCallback(() => {
		if (isAuthenticated) socket?.emit('leave-rooms');
	}, [socket, isAuthenticated]);

	const blockUserEmit = useCallback(
		(messParam: string[]) => {
			socket?.emit('info-message-room', { messages: messParam });
		},
		[socket]
	);

	const deleteEmit = useCallback(
		(message: string) => {
			socket?.emit('deleted-message', { message });
		},
		[socket]
	);

	const deleteProjectEmit = useCallback(
		(project: string) => {
			socket?.emit('deleted-project', { project });
		},
		[socket]
	);

	const addProjectEmit = useCallback(
		(project: string) => {
			socket?.emit('published-project', { project });
		},
		[socket]
	);

	const restrictEmit = useCallback(
		(data: any) => {
			socket?.emit('user-status', { ...data });
		},
		[socket]
	);

	const fetchQueryRooms = useCallback(
		(queryParams: IChatQuery) => {
			if (isAuthenticated) socket?.emit('fetch-rooms', { ...queryParams });
		},
		[socket, isAuthenticated]
	);

	const contextValue: SocketContextType = useMemo(
		() => ({
			socket,
			connectSocket,
			disconnectSocket,
			authenticateSocket,
			fetchRooms,
			joinRoom,
			leaveRoom,
			sendMessageToRoom,
			leaveAllRooms,
			blockUserEmit,
			deleteEmit,
			sendAttachmentsToRoom,
			deleteProjectEmit,
			addProjectEmit,
			fetchQueryRooms,
			setIsAuthenticated,
			isAuthenticated,
			restrictEmit
		}),
		// eslint-disable-next-line
		[socket, connectSocket, disconnectSocket, authenticateSocket, isAuthenticated]
	);

	return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};
