import { useCallback, useEffect, useState } from 'react';
import { notify } from '@/utils';
import { fetchOldRoomMessages } from './actions';
import { IUseOldMessagesReturnType } from './types';
import { IMessage } from '@/providers/chat-provider/types';

interface LoadingStates {
	all: boolean;
	prevLoading: boolean;
	nextLoading: boolean;
}

export function useOldMessages(
	messageId: string | undefined = undefined
): IUseOldMessagesReturnType {
	const [loading, setLoading] = useState<LoadingStates>({
		all: false,
		prevLoading: false,
		nextLoading: false,
	});

	const toggleLoading = (key: 'all' | 'prevLoading' | 'nextLoading', value: boolean): void => {
		setLoading((prev) => ({ ...prev, [key]: value }));
	};

	const [oldMessages, setOldMessages] = useState<IMessage[] | null>(null);
	const [previousCursor, setPreviousCursor] = useState<string | undefined>();
	const [nextCursor, setNextCursor] = useState<string | undefined>();

	const getOldMessages = useCallback(
		async (messageParam: string) => {
			try {
				toggleLoading('all', true);
				const response = await fetchOldRoomMessages(messageParam,);
				if (response) {
					console.log('response old', response);
					setOldMessages(response.messages);
					setPreviousCursor(response.previousCursor);
					setNextCursor(response.nextCursor);
				} else {
					notify.error('Nu am reușit să accesăm mesajele.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const getPrevMessages = useCallback(
		async (messageParam: string, prevParam?: string) => {
			try {
				toggleLoading('prevLoading', true);
				const response = await fetchOldRoomMessages(messageParam, prevParam);
				if (response) {
					console.log('response old', response);
					setOldMessages((prev) => ([...response.messages, ...(prev || [])]));
					setPreviousCursor(response.previousCursor);
				} else {
					notify.error('Nu am reușit să accesăm mesajele.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('prevLoading', false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const getNextMessages = useCallback(
		async (messageParam: string, nextParam?: string) => {
			try {
				toggleLoading('nextLoading', true);
				const response = await fetchOldRoomMessages(messageParam, undefined, nextParam);
				if (response) {
					console.log('response old', response);
					setOldMessages((prev) => ([...(prev || []), ...response.messages]));
					setNextCursor(response.nextCursor);
				} else {
					notify.error('Nu am reușit să accesăm mesajele.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('nextLoading', false);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const handlePrevMessages = (): void => {
		if (messageId && previousCursor) {
			getPrevMessages(messageId, previousCursor);
		}
	};

	const handleNextMessages = (): void => {
		if (messageId && nextCursor) {
			getNextMessages(messageId, nextCursor);
		}
	};

	useEffect(() => {
		if (messageId) {
			getOldMessages(messageId);
		}
		// eslint-disable-next-line
	}, [messageId]);

	return {
		loading: loading?.all,
		prevLoading: loading?.prevLoading,
		nextLoading: loading?.nextLoading,
		oldMessages,
		nextCursor,
		previousCursor,
		handlePrevMessages,
		handleNextMessages
	};
}
