import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { readFromLocalStorage } from '@/utils';

export const SessionExpireTimer: React.FC = () => {
	const [time, setTime] = useState<string>(' -- : --');

	// Function to calculate time left
	function timeLeft(futureTimestamp: number): string {
		const now = new Date();

		const futureDate = new Date(futureTimestamp);

		const diffInMillis = futureDate.getTime() - now.getTime();

		if (diffInMillis <= 0) {
			return '0 : 00';
		}

		const totalSeconds = Math.floor(diffInMillis / 1000);

		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;
		return `${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`;
	}

	const getSessionExpiryFromStorage = (): number | null => {
		const expiryStorage = readFromLocalStorage<{ sessionExpiry: string }>('@expiry');
		if (expiryStorage && expiryStorage.sessionExpiry) {
			const expiryTimestamp = Number(expiryStorage.sessionExpiry);
			if (!Number.isNaN(expiryTimestamp)) {
				return expiryTimestamp;
			}
		}
		return null;
	};

	useEffect(() => {
		const timerControl = (): void => {
			const sessionExpiry = getSessionExpiryFromStorage();
			if (sessionExpiry !== null) {
				const timeLeftValue = timeLeft(sessionExpiry);
				setTime(timeLeftValue);
			}
		};
		timerControl();
		// Set an interval to update the time every second
		const interval = setInterval(timerControl, 1000);
		// eslint-disable-next-line
		return () => clearInterval(interval);
	}, []);

	// Listen to changes in localStorage
	useEffect(() => {
		const handleStorageChange = (): void => {
			const sessionExpiry = getSessionExpiryFromStorage();
			if (sessionExpiry !== null) {
				const timeLeftValue = timeLeft(sessionExpiry);
				setTime(timeLeftValue);
			}
		};
		window.addEventListener('storage', handleStorageChange);
		// eslint-disable-next-line
		return () => window.removeEventListener('storage', handleStorageChange);
	}, []);

	// Determine if the time is below 5 minutes
	const isTimeCritical = time && parseInt(time.split(' : ')[0], 10) < 5;

	// Display expired message when time is zero
	if (time === '0 : 00') return (
		<Typography
			component="h1"
			variant="h6"
			color="inherit"
			noWrap
			sx={{
				fontSize: 14,
				borderRadius: 100,
				background: '#fff',
				color: 'red',
				border: '1px solid red',
				fontWeight: 500,
				padding: '5px 16px',
			}}
		>
			Timpul a expirat
		</Typography>
	);

	return (
		<Typography
			component="h1"
			variant="h6"
			color="inherit"
			noWrap
			sx={{
				fontSize: 14,
				borderRadius: 100,
				background: '#fff',
				color: isTimeCritical ? 'red' : '#043e7d',
				border: isTimeCritical ? '1px solid red' : '1px solid #043e7d',
				fontWeight: 500,
				padding: '5px 16px',
			}}
		>
			Timp rămas: <span style={{ fontWeight: 700 }}>{time}</span>
		</Typography>
	);
};
