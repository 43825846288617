import { AxiosError } from 'axios';
import { generateQueryString, get } from '@/utils';
import { IOldMessage } from './types';

async function fetchOldRoomMessages(
	message: string,
	previousCursor?: string,
	nextCursor?: string
): Promise<IOldMessage> {
	try {
		const query = generateQueryString({ previousCursor, nextCursor });
		const res = await get(`api/protected/chat/messages/${message}/goto${query}`);
		console.log('my old response', res);
		const data = res.data as unknown as IOldMessage;
		return data;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm mesajele.');
	}
}

export { fetchOldRoomMessages };
