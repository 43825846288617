/* eslint-disable */
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import {
	IAttachments,
	IChatContext,
	IChatRoom,
	ILastMessage,
	IMessage,
	ISelectedChatUser,
	ISubscribedProjects,
	IUserList
} from './types';
import { ChatContext } from './context';
import { useSocketContext } from '../socket';
import { del, get, notify } from '@/utils';
import { AuthState, useAuthContext } from '../auth';
import { IRestrictObj, useFileUploader, useRestrictions } from '@/hooks';
import { initialSimpleFilter, useFiltersContext } from '../filters-provider/context';
import { ISimpleFilter } from '../filters-provider/types';
import { IChatQuery } from '../socket/types';
import { fetchActionChatRooms, fetchActionQueryChatRooms, fetchActionRoomMessages, fetchUpdateRoom, postUnreadRoom } from './actions';
import { AxiosError } from 'axios';

interface ILoadingUsers {
	all: boolean;
	details: boolean;
}

const sortRoomsByLastMessage = (rooms: IChatRoom[]): IChatRoom[] =>
	rooms.sort((a, b) => {
		const aDate = a.lastMessage?.createdAt ? new Date(a.lastMessage.createdAt).getTime() : 0;
		const bDate = b.lastMessage?.createdAt ? new Date(b.lastMessage.createdAt).getTime() : 0;
		return bDate - aDate;
	});

const formatSimpleFilter = (simpleFilter: ISimpleFilter): IChatQuery => {
	const formattedFilter: IChatQuery = {};

	if (simpleFilter.search) {
		formattedFilter.search = simpleFilter.search;
	}
	if (simpleFilter.status === 'published' || simpleFilter.status === 'archived') {
		formattedFilter.status = simpleFilter.status;
	}
	if (simpleFilter.county) {
		formattedFilter.county = simpleFilter.county;
	}
	if (simpleFilter.uat) {
		formattedFilter.uat = simpleFilter.uat;
	}
	if (simpleFilter.seen !== null) {
		formattedFilter.seen = simpleFilter.seen;
	}

	return formattedFilter;
};

export const ChatProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const {
		socket,
		joinRoom,
		leaveRoom,
		blockUserEmit,
		sendMessageToRoom,
		leaveAllRooms,
		deleteEmit,
		sendAttachmentsToRoom,
		isAuthenticated
	} = useSocketContext();
	const { simpleFilter, setApplyFilter, setClearFilter, setSimpleFilter } = useFiltersContext();

	const [chatRooms, setChatRooms] = useState<IChatRoom[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedRoom, setSelectedRoom] = useState<IChatRoom | null>(null);
	const [roomMessages, setRoomMessages] = useState<IMessage[] | null>(null);
	const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
	const [hasUserList, setHasUserList] = useState<boolean>(false);
	const [interestUserId, setInterestUserId] = useState<string | null>(null);
	const [selectedUser, setSelectedUser] = useState<ISelectedChatUser | null>(null);
	const [userTab, setUserTab] = useState<number>(1);
	const [loadingUsers, setLoadingUsers] = useState<ILoadingUsers>({ all: true, details: true });
	const [userList, setUserList] = useState<IUserList[] | null>(null);
	const [searchUserParam, setSearchUserParam] = useState<string | null>('');
	// projects
	const [selectedProject, setSelectedProject] = useState<ISubscribedProjects | null>(null);
	const [projects, setProjects] = useState<ISubscribedProjects[] | null>(null);
	const [loadingProjectMessages, setLoadingProjectMessages] = useState<boolean>(false);
	const [searchMessage, setSearchMessage] = useState<string | null>('');
	const [projectMessages, setProjectMessages] = useState<IMessage[] | null>(null);
	// media
	const { addFiles, removeFile, loading: loadingMedia } = useFileUploader<IAttachments[]>();
	const [media, setMedia] = useState<IAttachments[] | null>(null);
	const [openMedia, setOpenMedia] = useState<boolean>(false);
	const [redirectMessage, setRedirectMessage] = useState<string | null>(null);
	// pagination
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(15);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [loadingMore, setLoadingMore] = useState<boolean>(false);
	const [scrollMyMessage, setScrollMyMessage] = useState<boolean>(false);

	const {
		blockChatUser,
		unblockUser,
		unmuteUser,
		loading: restrictLoading,
		muteChatUser
	} = useRestrictions();
	const { user, refreshAuthState } = useAuthContext();

	const getUserProjects = useCallback(async (userId: string) => {
		try {
			const response = await get(`api/protected/chat/rooms?user=${userId}`);
			if (response.status === 200) {
				const data = response.data as unknown as { projects: ISubscribedProjects[] };
				setProjects(data.projects);
			}
		} catch (e) {
			console.log('user error', e);
		}
	}, []);

	useEffect(() => {
		if (selectedUser && selectedUser?._id) {
			getUserProjects(selectedUser?._id);
		} else {
			setProjects(null);
		}
		// eslint-disable-next-line
	}, [selectedUser?._id]);

	useEffect(() => {
		// eslint-disable-next-line
		setApplyFilter(() => () => {
			setLoading(true);
			getQueryRooms(formatSimpleFilter(simpleFilter));
		});
		// eslint-disable-next-line
		setClearFilter(() => () => {
			setSimpleFilter(initialSimpleFilter);
			setLoading(true);
			if (isAuthenticated) fetchChatRooms();
		});
		// eslint-disable-next-line
	}, [simpleFilter, isAuthenticated]);

	const handleUnblockSuccess = (): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.muted ? 'muted' : 'active',
				blockMuteHistory: [
					{
						event: 'unblock',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				blocked: false
			});
			notify.info('Utilizator deblocat');
		}
	};

	const handleUnblock = (userId: string, onSuccess: () => void): void => {
		unblockUser(userId, onSuccess);
	};

	const handleBlockSuccess = (restrictObj: IRestrictObj): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: 'blocked',
				blockMuteHistory: [
					{
						event: 'block',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
							reason: restrictObj.reason,
							until: restrictObj.until
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				blocked: true
			});
			notify.success('Utilizator blocat');
		}
	};

	const handleBlock = (userId: string, restrictObj: IRestrictObj, onSuccess: () => void): void => {
		blockChatUser(userId, { reason: restrictObj.reason, until: restrictObj.until }, (messages) => {
			blockUserEmit(messages);
			onSuccess && onSuccess();
		});
	};

	// Mute actions
	const handleUnmuteSuccess = (): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.blocked ? 'blocked' : 'active',
				blockMuteHistory: [
					{
						event: 'unmute',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				muted: false
			});
			notify.info('Utilizator derestricționat');
		}
	};

	const handleUnmute = (userId: string, onSuccess: () => void): void => {
		unmuteUser(userId, onSuccess);
	};

	const handleMuteSuccess = (restrictObj: IRestrictObj): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.status === 'blocked' ? selectedUser?.status : 'muted',
				blockMuteHistory: [
					{
						event: 'mute',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
							reason: restrictObj.reason,
							until: restrictObj.until
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory : [])
				],
				muted: true
			});
			notify.success('Utilizator restricționat');
		}
	};

	const handleMute = (userId: string, restrictObj: IRestrictObj, onSuccess: () => void): void => {
		muteChatUser(userId, { reason: restrictObj.reason, until: restrictObj.until }, (messages) => {
			blockUserEmit(messages);
			onSuccess && onSuccess();
		});
	};

	const toggleUserLoading = (key: 'all' | 'details', val: boolean): void => {
		setLoadingUsers((prev) => ({
			...prev,
			[key]: val
		}));
	};

	// eslint-disable-next-line
	useEffect(() => {
		if (socket) {

			socket.on('kick', () => {
				notify.info('Proiectul a fost arhivat!');
				setSelectedRoom((prev) => ({
					...(prev || {} as IChatRoom),
					status: 'archived'
				}))
			});

			socket.on('duplicate-auth', (data: { message: string }) => {
				notify.info(data?.message);
			});

			socket.on('last-message', (data: { room: string, lastMessage: ILastMessage }) => {
				updateRoom(data);
			});

			socket.on('trigger-fetch-room', (data: { room: string }) => {
				updateTriggerRoom(data);
			});

			socket.on('new-message', (data: IMessage) => {
				if (selectedRoom) {
					setRoomMessages((prev) => ([...(prev ? prev : []), data]));
				}
			});

			socket.on('remove-message', (messageObj: { message: string }) => {
				const messageId = messageObj.message;
				if (roomMessages) {
					// Find the index of the message to be updated
					const index = roomMessages.findIndex((prevMessage) => {
						const prevMessageId = String(prevMessage._id);
						return prevMessageId === messageId;
					});

					if (index !== -1) {
						const updatedMessages = [...roomMessages];
						updatedMessages[index] = { ...updatedMessages[index], deleted: true };
						// Set the updated array as the new state
						setRoomMessages(updatedMessages);
					} else {
						console.log('Message not found:', messageId);
					}
				}
			});

			socket.on('errors', (error: { statusCode: number; message: string }) => {
				console.error('Socket error:', error);
				notify.error(error?.message);
				setLoading(false);
				if (error?.statusCode === 401 && error?.message === 'Unauthorized') {
					refreshAuthState(AuthState.SignedOut);
				}
			});

			// Clean up listeners when the component unmounts or socket changes
			// eslint-disable-next-line
			return () => {
				socket.off('rooms');
				socket.off('kick');
				socket.off('room');
				socket.off('new-message');
				socket.off('errors');
				socket.off('last-message');
				socket.off('trigger-fetch-room');
				socket.off('remove-message');
				socket.off('duplicate-auth');
			};
		}
		// eslint-disable-next-line
	}, [socket, selectedRoom, roomMessages, isAuthenticated]);

	const getRooms = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchActionChatRooms();
				const sortedRooms = sortRoomsByLastMessage(response);
				setChatRooms(sortedRooms);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setChatRooms]
	);

	const updateRoom = useCallback(
		async (data: { room: string, lastMessage: ILastMessage }) => {
			try {
				setChatRooms((prevChatRooms) => {
					const updatedRooms = prevChatRooms.map((chatRoom) =>
						chatRoom.id === data?.room ? ({
							...chatRoom, lastMessage: data?.lastMessage,
							unreadMessages: selectedRoom?.id === data?.room ? 0 : chatRoom?.unreadMessages + 1
						}) : chatRoom
					);

					return sortRoomsByLastMessage(updatedRooms);
				});
			} catch (message) {
				notify.error(`${message}`);
			}
		},
		[setChatRooms, selectedRoom]
	);

	const updateTriggerRoom = useCallback(
		async (data: { room: string }) => {
			try {
				const myRoom = await fetchUpdateRoom(data.room);
				if (myRoom) {
					setChatRooms((prevChatRooms) => {
						const roomExists = prevChatRooms.some((chatRoom) => chatRoom.id === data?.room);

						if (roomExists) {
							// Update the existing room
							const updatedRooms = prevChatRooms.map((chatRoom) =>
								chatRoom.id === data?.room ? myRoom : chatRoom
							);
							return sortRoomsByLastMessage(updatedRooms);
						} else {
							// Add the new room to the top
							return sortRoomsByLastMessage([myRoom, ...prevChatRooms]);
						}
					});
				}
			} catch (message) {
				notify.error(`${message}`);
			}
		},
		[setChatRooms, selectedRoom]
	);

	const getQueryRooms = useCallback(
		async (queryParam: IChatQuery) => {
			try {
				setLoading(true);
				const response = await fetchActionQueryChatRooms(queryParam);
				const sortedRooms = sortRoomsByLastMessage(response);
				setChatRooms(sortedRooms);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setChatRooms]
	);

	const getMessages = useCallback(
		async (roomParam: string) => {
			try {
				setLoadingMessages(true);
				const response = await fetchActionRoomMessages(roomParam, page, items);
				response?.length === items && setHasMore(true);
				setRoomMessages(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingMessages(false);
			}
		},
		// eslint-disable-next-line
		[fetchActionRoomMessages]
	);

	const getPaginatedMessages = useCallback(
		async (roomParam: string, pageParam: number) => {
			try {
				setLoadingMore(true);
				const response = await fetchActionRoomMessages(roomParam, pageParam, items);
				response?.length === items && setHasMore(true);
				setHasMore(response?.length === items);
				setRoomMessages((prev) => ([...response, ...(prev || [])]));
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingMore(false);
			}
		},
		// eslint-disable-next-line
		[fetchActionRoomMessages, items]
	);

	const handleLoadMore = useCallback(() => {
		hasMore && !loadingMore && !loadingMessages && setPage((prevPage) => prevPage + 1);
	}, [hasMore, !loadingMore]);

	useEffect(() => {
		if (selectedRoom && hasMore && !loadingMore && page !== 0) {
			getPaginatedMessages(selectedRoom?.id, page)
		}
		// eslint-disable-next-line
	}, [page])

	const fetchChatRooms = useCallback(() => {
		try {
			getRooms();
		} catch (e) {
			console.error('Failed to fetch chat rooms:', e);
			setLoading(false);
		}
	}, [getRooms]);

	useEffect(() => {
		selectedRoom && getMessages(selectedRoom?.id);
	}, [selectedRoom])

	const sendRoomMessage = useCallback(
		async (room: string, message: string) => {
			try {
				const res = await sendMessageToRoom(room, message);
				if (res !== 201) {
					notify.error('Nu am reusit sa trimitem mesajul');
				}
			} catch (err) {
				const error = err as AxiosError;
				const data = error.response?.data as { error: string };
				notify.error(data?.error || 'Nu am reusit sa trimitem mesajul');
			}
		},
		[sendMessageToRoom]
	);

	const setSearch = useCallback((newString: string) => {
		const updateSearchParam = debounce((searchValue: string) => {
			if (searchValue.length > 2) {
				setSearchUserParam(searchValue);
			} else {
				setSearchUserParam(null);
			}
		}, 300);

		updateSearchParam(newString);
		// eslint-disable-next-line
		return () => {
			updateSearchParam.cancel();
		};
	}, []);

	const setSearchProjectMessage = useCallback((newString: string) => {
		const updateSearchParam = debounce((searchValue: string) => {
			if (searchValue.length > 2) {
				setSearchMessage(searchValue);
			} else {
				setSearchMessage(null);
			}
		}, 300);

		updateSearchParam(newString);
		// eslint-disable-next-line
		return () => {
			updateSearchParam.cancel();
		};
	}, []);

	const selectRoom = useCallback(
		(room: IChatRoom, isRedirect?: boolean) => {
			selectedRoom && leaveRoom(selectedRoom?.id);
			isRedirect !== true && setHasUserList(false);
			setRoomMessages(null);
			setLoadingMessages(true);
			setSearchUserParam(null);
			setSearchMessage(null);
			setPage(0);
			setHasMore(false);
			setLoadingMore(false);
			isRedirect !== true && setSelectedUser(null);
			isRedirect !== true && setInterestUserId(null);
			isRedirect !== true && setSelectedProject(null);
			isRedirect !== true && setProjectMessages(null);
			isRedirect !== true && setRedirectMessage(null);
			setMedia(null);
			joinRoom(room.id);
			setSelectedRoom({ ...room, unreadMessages: 0 });
			setChatRooms((prevChatRooms) => {
				const updatedRooms = prevChatRooms.map((chatRoom) =>
					chatRoom.id === room?.id ? ({
						...chatRoom, unreadMessages: 0
					}) : chatRoom
				);

				return sortRoomsByLastMessage(updatedRooms);
			});
		},
		[joinRoom, selectedRoom]
	);

	const deselectRoom = useCallback(
		(room: string) => {
			leaveRoom(room);
			setSearchUserParam(null);
			setPage(0);
			setHasMore(false);
			setLoadingMore(false);
			setSearchMessage(null);
			setSelectedRoom(null);
			setSelectedUser(null);
			setInterestUserId(null);
			setRoomMessages([]);
			setSelectedProject(null);
			setProjectMessages(null);
		},
		[leaveRoom]
	);

	const clearRooms = useCallback(() => {
		leaveAllRooms();
		setSearchUserParam(null);
		setSearchMessage(null);
		setSelectedRoom(null);
		setSelectedUser(null);
		setInterestUserId(null);
		setRoomMessages([]);
		setSelectedProject(null);
		setProjectMessages(null);
		setPage(0);
		setHasMore(false);
		setLoadingMore(false);
		setItems(15);
	}, [leaveAllRooms]);

	const getUserList = useCallback(async (gueryParam: string) => {
		try {
			toggleUserLoading('all', true);
			const response = await get(`api/protected/chat/users?${gueryParam}`);
			if (response.status === 200) {
				const data = response.data as unknown as { users: ISelectedChatUser[] };
				setUserList(data.users);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			toggleUserLoading('all', false);
		}
	}, []);

	const getUserDetails = useCallback(async (userId: string) => {
		try {
			toggleUserLoading('details', true);
			const response = await get(`api/protected/chat/users/${userId}`);
			if (response.status === 200) {
				const data = response.data as unknown as { user: ISelectedChatUser };
				setSelectedUser(data.user);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			toggleUserLoading('details', false);
		}
	}, []);

	const getProjectMessages = useCallback(async (gueryParam: string) => {
		try {
			setLoadingProjectMessages(true);
			const response = await get(`api/protected/chat/messages?${gueryParam}`);
			if (response.status === 200) {
				const data = response.data as unknown as { messages: IMessage[] };
				setProjectMessages(data.messages);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			setLoadingProjectMessages(false);
		}
	}, []);

	const deleteProjectMessage = useCallback(
		async (messageId: string, onSuccess?: () => void) => {
			try {
				setLoadingProjectMessages(true);
				const response = await del(`api/protected/chat/messages/${messageId}`);
				if (response.status === 200) {
					setProjectMessages(
						() =>
							projectMessages?.map((message) =>
								message?._id === messageId ? { ...message, deleted: true } : message
							) || []
					);
					onSuccess && onSuccess();
					deleteEmit(messageId);
				}
			} catch (e) {
				console.log('user error', e);
			} finally {
				setLoadingProjectMessages(false);
			}
		},
		// eslint-disable-next-line
		[projectMessages]
	);

	const handleSendAttachmentsToRoom = useCallback(
		async (room: string, attachments: IAttachments[]) => {
			try {
				const res = await sendAttachmentsToRoom(room, attachments);
				if (res !== 201) {
					notify.error('Nu am reusit sa trimitem mesajul');
					setMedia(null);
					setOpenMedia(false);
				}
				if (res === 201) {
					setScrollMyMessage(true);
					setMedia(null);
					setOpenMedia(false);
				}
			} catch (err) {
				const error = err as AxiosError;
				const data = error.response?.data as { error: string };
				notify.error(data?.error || 'Nu am reusit sa trimitem mesajul');
				setMedia(null);
				setOpenMedia(false);
			}
		},
		[sendMessageToRoom]
	);

	const addMediaMessage = (): void => {
		selectedRoom && media && handleSendAttachmentsToRoom(selectedRoom.id, media);
		setMedia(null);
		setOpenMedia(false);
	};

	const removeMedia = (): void => {
		if (!media || media.length === 0) return;

		const removeNext = (index: number): void => {
			if (index >= media.length) {
				setMedia(null);
				setOpenMedia(false);
				return;
			}
			removeFile(media[index].url, () => removeNext(index + 1));
		};

		removeNext(0);
	};

	const handleUnseen = useCallback(
		async (room: string) => {
			clearRooms();
			try {
				const res = await postUnreadRoom(room);
				console.log('unseen response', res);
			} catch (err) {
				console.error('error updating unseen room', err);
			}
		}
		, []);

	useEffect(() => {
		if (selectedRoom && hasUserList) {
			const query =
				searchUserParam && searchUserParam?.length > 2
					? `room=${selectedRoom.id}&search=${searchUserParam}`
					: `room=${selectedRoom.id}`;
			getUserList(query);
		}
		// eslint-disable-next-line
	}, [selectedRoom, hasUserList, searchUserParam]);

	useEffect(() => {
		if (interestUserId) {
			getUserDetails(interestUserId);
		} else {
			setSelectedUser(null);
		}
		// eslint-disable-next-line
	}, [interestUserId]);

	// fetch project messages
	useEffect(() => {
		if (selectedProject && selectedUser) {
			const query =
				searchMessage && searchMessage?.length > 2
					? `room=${selectedProject?._id}&user=${selectedUser?._id}&search=${searchMessage}`
					: `room=${selectedProject?._id}&user=${selectedUser?._id}`;
			getProjectMessages(query);
		}
		// eslint-disable-next-line
	}, [selectedProject, selectedUser, searchMessage]);

	const handleRedirectMessage = (redirectId: string): void => {
		setRedirectMessage(redirectId);
		if (selectedRoom?.id !== selectedProject?._id) {
			const interestRoom = chatRooms?.find((r) => r?.id === selectedProject?._id);
			interestRoom && selectRoom(interestRoom, true);
		}
	};

	const value: IChatContext = useMemo(
		() => ({
			chatRooms,
			fetchChatRooms,
			loading,
			selectedRoom,
			selectRoom,
			setSelectedRoom,
			loadingMessages,
			roomMessages,
			setRoomMessages,
			deselectRoom,
			sendRoomMessage,
			hasUserList,
			setHasUserList,
			selectedUser,
			setSelectedUser,
			userTab,
			setUserTab,
			getUserList,
			loadingUsers: loadingUsers.all,
			loadingUserDetails: loadingUsers.details,
			userList,
			setSearch,
			setSearchUserParam,
			interestUserId,
			setInterestUserId,
			restrictLoading,
			handleBlock,
			handleBlockSuccess,
			handleMute,
			handleMuteSuccess,
			handleUnblock,
			handleUnblockSuccess,
			handleUnmute,
			handleUnmuteSuccess,
			selectedProject,
			setSelectedProject,
			loadingProjectMessages,
			projectMessages,
			clearRooms,
			loadingMedia,
			addFiles,
			addMediaMessage,
			removeMedia,
			media,
			setMedia,
			openMedia,
			setOpenMedia,
			setSearchMessage,
			setSearchProjectMessage,
			deleteProjectMessage,
			projects,
			handleRedirectMessage,
			redirectMessage,
			setRedirectMessage,
			isAuthenticated,
			handleLoadMore,
			hasMore,
			items,
			loadingMore,
			page,
			setHasMore,
			setItems,
			setPage,
			scrollMyMessage,
			setScrollMyMessage,
			handleUnseen
		}),
		// eslint-disable-next-line
		[
			chatRooms,
			fetchChatRooms,
			loading,
			selectedRoom,
			selectRoom,
			loadingMessages,
			roomMessages,
			hasUserList,
			selectedUser,
			userTab,
			loadingUsers,
			userList,
			interestUserId,
			restrictLoading,
			selectedProject,
			loadingProjectMessages,
			projectMessages,
			loadingMedia,
			media,
			openMedia,
			projects,
			redirectMessage,
			isAuthenticated,
			hasMore,
			items,
			loadingMore,
			page,
			scrollMyMessage
		]
	);

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
