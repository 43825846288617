import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Clear, Save } from '@mui/icons-material';
import { Flex } from '../flex';
import { useFileUploader } from '@/hooks';
import { notify, stateSetter } from '@/utils';

async function blobUrlToFile(blobUrl: string, fileName: string): Promise<File> {
	const response = await fetch(blobUrl);
	const blob = await response.blob();
	return new File([blob], fileName, { type: blob.type });
}

interface IAddAvatarComponent {
	cloudHandler: stateSetter<string>;
	labelParam?: string;
}

export const AddAvatarComponent: FC<IAddAvatarComponent> = ({ cloudHandler, labelParam = 'avatar' }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [image, setImage] = useState<string>('');
	const [cloudImg, setCloudImg] = useState<string>('');
	const { addFile, removeFile, loading } = useFileUploader();

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fileUrl = URL.createObjectURL(file);
			setImage(fileUrl);
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const handleButtonClick = useCallback(() => {
		fileInputRef.current?.click();
	}, []);

	const handleRemoveImage = (file: string): void => {
		removeFile(file, () => {
			setCloudImg('');
			setImage('');
			notify.info('Imagine ștearsă');
		});
	};

	const handleClear = (): void => {
		if (cloudImg) {
			handleRemoveImage(cloudImg);
		} else {
			setImage('');
		}
	};

	const handleSave = async (): Promise<void> => {
		const myFile = await blobUrlToFile(image, 'selectedAvatar.jpg');
		addFile(myFile, (fileUrl) => {
			setCloudImg(fileUrl);
			notify.info('Imagine adăugată');
		});
	};

	useEffect(() => {
		if (cloudImg?.length > 0) {
			cloudHandler(cloudImg);
		} else {
			cloudHandler('');
		}
		// eslint-disable-next-line
	}, [cloudImg]);

	return (
		<Flex width={'100%'} column >
			<Flex width={'100%'} justifyCenter gap={2} sx={{ position: 'relative' }} >
				{cloudImg?.length === 0 && image?.length > 0 &&
					<Tooltip title='Șterge avatar'>
						<IconButton onClick={handleClear}>
							<Clear />
						</IconButton>
					</Tooltip>
				}
				<Avatar src={image} alt={'avatar-component'} sx={{
					width: '80px', height: '80px', border: '1px solid #9e9e9e',
				}} />
				{cloudImg?.length === 0 && image?.length > 0 &&
					<Tooltip title='Salvează avatar'>
						<IconButton onClick={handleSave}>
							<Save />
						</IconButton>
					</Tooltip>
				}
				{loading &&
					<Box sx={{ position: 'absolute' }}>
						<CircularProgress />
					</Box>
				}
			</Flex>
			<Box>
				<input
					type="file"
					accept=".png, .jpg, .jpeg, .webp"
					onChange={handleFileChange}
					ref={fileInputRef}
					style={{ display: 'none' }}
				/>
				<Button variant='text' onClick={cloudImg?.length > 0 ? handleClear : handleButtonClick}
					sx={{ my: 2 }}>
					{/* eslint-disable-next-line */}
					{cloudImg?.length === 0 ? image?.length > 0 ?
						`Schimbă ${labelParam}` : `+ Adaugă ${labelParam}` : ''
					}
					{cloudImg?.length > 0 && 'Șterge imaginea'}
				</Button>
			</Box>
		</Flex>
	);
};

AddAvatarComponent.defaultProps = {
	labelParam: 'avatar'
};