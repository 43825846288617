import { createContext, useContext } from 'react';
import { IChatContext } from './types';

export const ChatContext = createContext<IChatContext>({
	chatRooms: [],
	fetchChatRooms: () => { },
	loading: false,
	selectedRoom: null,
	setSelectedRoom: () => { },
	selectRoom: () => { },
	loadingMessages: false,
	roomMessages: null,
	setRoomMessages: () => { },
	deselectRoom: () => { },
	sendRoomMessage: () => { },
	hasUserList: false,
	setHasUserList: () => { },
	selectedUser: null,
	setSelectedUser: () => { },
	setUserTab: () => { },
	userTab: 1,
	userList: null,
	getUserList: () => { },
	loadingUsers: false,
	// eslint-disable-next-line
	setSearch: () => () => { },
	setSearchUserParam: () => { },
	interestUserId: null,
	setInterestUserId: () => { },
	loadingUserDetails: true,
	restrictLoading: false,
	handleBlock: () => { },
	handleBlockSuccess: () => { },
	handleMute: () => { },
	handleMuteSuccess: () => { },
	handleUnblock: () => { },
	handleUnblockSuccess: () => { },
	handleUnmute: () => { },
	handleUnmuteSuccess: () => { },
	selectedProject: null,
	setSelectedProject: () => { },
	loadingProjectMessages: false,
	projectMessages: null,
	clearRooms: () => { },
	addMediaMessage: () => { },
	loadingMedia: false,
	removeMedia: () => { },
	media: null,
	setMedia: () => { },
	openMedia: false,
	setOpenMedia: () => { },
	// eslint-disable-next-line
	setSearchProjectMessage: () => () => { },
	setSearchMessage: () => { },
	projects: null,
	handleRedirectMessage: () => { },
	redirectMessage: null,
	setRedirectMessage: () => { },
	isAuthenticated: false,
	handleLoadMore: () => { },
	hasMore: false,
	items: 15,
	loadingMore: false,
	page: 0,
	setHasMore: () => { },
	setItems: () => { },
	setPage: () => { },
	scrollMyMessage: false,
	setScrollMyMessage: () => { },
	handleUnseen: () => Promise.resolve(),
});

export const useChatContext = (): IChatContext => useContext(ChatContext);
