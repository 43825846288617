import { useEffect, useCallback, useState } from 'react';
import { get } from '@/utils';

interface IProps {
	resetTimer: () => void;
}

export const useInactiveRefresh = (): IProps => {
	const [refreshing, setRefreshing] = useState<number>(4 * 60 * 1000);

	const refreshToken = async (): Promise<void> => {
		try {
			await get('api/auth/refresh');
		} catch (error) {
			console.error('Error refreshing token:', error);
		} finally {
			setRefreshing(4 * 60 * 1000);
		}
	};

	useEffect(() => {
		const timer = setInterval(() => {
			setRefreshing(prevRefreshing => prevRefreshing - 1000);
		}, 1000);

		// eslint-disable-next-line
		return () => clearInterval(timer);
	}, []);

	useEffect(() => {
		if (refreshing === 0) {
			refreshToken();
		}
	}, [refreshing]);

	useEffect(() => {
		refreshToken();
	}, []);

	const resetTimer = useCallback(() => {
		setRefreshing(4 * 60 * 1000);
	}, []);

	return { resetTimer };
};
