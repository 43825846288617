import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import {
	MenuButtonBold,
	MenuButtonBulletedList,
	MenuButtonHighlightColor,
	MenuButtonItalic,
	MenuButtonOrderedList,
	MenuButtonRedo,
	MenuButtonStrikethrough,
	MenuButtonTextColor,
	MenuButtonUnderline,
	MenuButtonUndo,
	MenuControlsContainer,
	MenuDivider,
	MenuSelectHeading,
	MenuSelectTextAlign,
	RichTextEditor,
	type RichTextEditorRef
} from 'mui-tiptap';
import { FC, useRef } from 'react';
import './style.css';

interface TextEditorProps {
	onUpdate?: (newVal: string) => void;
	onGetPlain?: (newVal: string) => void;
	descriptionData?: string;
	editable?: boolean;
}

export const TextEditor: FC<TextEditorProps> = ({ onUpdate, onGetPlain, descriptionData, editable }) => {
	const rteRef = useRef<RichTextEditorRef>(null);

	return (
		<>
			<RichTextEditor
				className='text-editor-style'
				ref={rteRef}
				extensions={[
					StarterKit,
					TextStyle,
					Color,
					TextAlign.configure({
						types: ['heading', 'paragraph'],
					}),
					Highlight.configure({ multicolor: true }),
					Underline,
				]}
				editable={editable}
				content={descriptionData || '<p>Adaugă descriere</p>'}
				renderControls={() => (
					<MenuControlsContainer>

						<MenuButtonUndo />
						<MenuButtonRedo />
						<MenuDivider />

						<MenuSelectHeading />
						<MenuDivider />

						<MenuSelectTextAlign />
						<MenuDivider />

						<MenuButtonTextColor
							defaultTextColor={'#000000'}
							swatchColors={[
								{ value: '#000000', label: 'Black' },
								{ value: '#ffffff', label: 'White' },
								{ value: '#888888', label: 'Grey' },
								{ value: '#ff0000', label: 'Red' },
								{ value: '#ff9900', label: 'Orange' },
								{ value: '#ffff00', label: 'Yellow' },
								{ value: '#00d000', label: 'Green' },
								{ value: '#0000ff', label: 'Blue' },
							]}
						/>
						<MenuDivider />

						<MenuButtonBold />
						<MenuButtonItalic />
						<MenuButtonUnderline />
						<MenuButtonStrikethrough />
						<MenuDivider />

						<MenuButtonHighlightColor
							swatchColors={[
								{ value: '#595959', label: 'Dark grey' },
								{ value: '#dddddd', label: 'Light grey' },
								{ value: '#ffa6a6', label: 'Light red' },
								{ value: '#ffd699', label: 'Light orange' },
								{ value: '#ffff00', label: 'Yellow' },
								{ value: '#99cc99', label: 'Light green' },
								{ value: '#90c6ff', label: 'Light blue' },
								{ value: '#8085e9', label: 'Light purple' },
							]}
						/>
						<MenuDivider />

						<MenuButtonOrderedList />
						<MenuButtonBulletedList />
						<MenuDivider />
					</MenuControlsContainer>
				)}
				onUpdate={() => {
					onUpdate && onUpdate(rteRef.current?.editor?.getHTML() || '<p></p>');
					onGetPlain && onGetPlain(rteRef.current?.editor?.getText() || '');
				}}
			/>
			{/* method to display the editor text */}
			{/* <div>
				<div dangerouslySetInnerHTML={{ __html: rteRef.current?.editor?.getHTML() || 'da'}}/>
			</div> */}

			{/* <Button onClick={() => console.log(rteRef.current?.editor?.getHTML())}>
				Log HTML
			</Button> */}
		</>
	);
};

TextEditor.defaultProps = {
	onUpdate: undefined,
	onGetPlain: undefined,
	descriptionData: '<p>Adaugă descriere</p>',
	editable: true
};